import { PlatformViewProps } from "../model";
import { TextRoller } from "../../animations/views/text-roller";
import { For, splitProps } from "solid-js";

export function Platform(props: PlatformViewProps) {
  const [local, other] = splitProps(props, ["title", "msg"]);
  return (
    <div {...other}>
      <h1 class="flex$">
        <TextRoller word={local.title} />
      </h1>
      <For each={local.msg.split("\n")}>
        {(item) => {
          return <p>{item}</p>;
        }}
      </For>
    </div>
  );
}
